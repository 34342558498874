import { useStore } from "@/store"
import { ActionTypes as AuthActions } from "@/store/modules/auth/actions"
import { useRouter, useRoute } from "vue-router"
import { toast } from "vue3-toastify"
import "vue3-toastify/dist/index.css"
import i18n from "@/plugins/i18n"
import axios from "axios"
import { computed } from "vue"
const resellerAssessmentHosts = [
    "assessments.join-staging.com",
    "assessments.join.com",
]
const preventNotificationStatusCodes = [200, 204, 403, 401]

const MAX_RETRIES = 3

export default function useCatchHandler() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const isPaymentSucceeded = computed(() => {
        return route.query.state === "succeeded"
    })

    const handleError = async (response: any) => {
        if (!response) return -1 // Handle cases where response is undefined

        const errors: any = response.data?.error
        let message =
            errors?.details?.[0]?.message ||
            errors?.message ||
            "An error occurred"

        // 🔄 Retry Logic for 401 (Unauthorized)
        if (response.status === 401 && response.config) {
            response.config._retry = response.config._retry ?? 0

            if (response.config._retry < MAX_RETRIES) {
                response.config._retry += 1

                // Exponential Backoff (1s, 2s, 4s)
                await new Promise((resolve) =>
                    setTimeout(
                        resolve,
                        Math.pow(2, response.config._retry) * 1000
                    )
                )

                try {
                    return await axios(response.config) // 🔄 Retry request
                } catch (error) {
                    return handleError(error.response) // Handle further failures
                }
            }

            // Max retries reached, force logout
            router.push({ name: "Login", query: { action: "clear" } })
            store.dispatch(AuthActions.LOG_OUT)
            return -1
        }

        // Handle Subscription Expiry (402)
        if (
            response.status === 402 &&
            message === "SUBSCRIPTION EXPIRED" &&
            !isPaymentSucceeded.value
        ) {
            if (!sessionStorage.getItem("isSubscriptionExpired")) {
                sessionStorage.setItem("isSubscriptionExpired", "true")
                toast(i18n.global.t("subscription_expired.expired"), {
                    type: "error",
                    autoClose: 4000,
                    position: "bottom-left",
                })
                router.push({
                    name: "SubscriptionExpired",
                })
            }
        }

        // Handle Forbidden (403)
        if (response.status === 403 && message === "Record exist") {
            router.push({ name: "Login", query: { action: "clear" } })
            store.dispatch(AuthActions.LOG_OUT)
        }

        if (
            response.status === 403 &&
            message === "Verification is mandatory"
        ) {
            toast(message, {
                type: "error",
                autoClose: 4000,
                position: "bottom-left",
            })
        }

        if (response.status === 403 && message === "Unverified user") {
            toast(i18n.global.t("dashboard_header.verify_error"), {
                type: "error",
                autoClose: 4000,
                position: "bottom-left",
            })
        }

        if (response.status === 400 && message === "Link Not found") {
            router.push({ name: "InvitationExpired" })
        }

        if (response.status === 403 && message === "User Already Exists") {
            toast(i18n.global.t("dashboard_header.user_exist_error"), {
                type: "error",
                autoClose: 4000,
                position: "bottom-left",
            })
        }

        if (
            response.status === 401 &&
            !resellerAssessmentHosts.includes(window.location.host)
        ) {
            toast(message, {
                type: "error",
                autoClose: 4000,
                position: "bottom-left",
            })
        }
        if (response.status === 423 && message === "2FA Required") {
            router.push({ name: "TwoFactorSetupModal" })
        }

        if (
            !preventNotificationStatusCodes.includes(response.status) &&
            message !== "SUBSCRIPTION EXPIRED"
        ) {
            toast(message, {
                type: "error",
                autoClose: 4000,
                position: "bottom-left",
            })
        }

        return -1
    }

    return { handleError }
}
